<template>
  <div id="demo-basic-card">
    <RefreshChats
      class="w-full h-full flex mb-5"
      :class="{'flex-row items-center justify-center lg:justify-end': hiddenChats.length, 'flex-col items-center justify-center': !hiddenChats.length}"
      :haveChats="!!hiddenChats.length"
    />

    <a-row
      :gutter="[16,16]"
      class="mb-5"
      type="flex"
    >
      <a-col
        v-for="(chat, key) in hiddenChats"
        :key="`chat-${key}-${chat.chat_id}`"
        :xs="24"
        :sm="12"
        :md="24"
        :lg="12"
        :xxl="8"
      >
        <chat-card
          :chat="chat"
          statistics-loaded
          @updateChatInfo="updateChatInfo"
        />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import RefreshChats from "@/components/RefreshChats";
import ChatCard from "@/components/vu-components/ChatCard";

export default {
  components: {
    ChatCard,
    RefreshChats,
  },
  methods: {
    updateChatInfo(data)  {
      if (data) {
        this.$store.commit('updateChatInfo', {
          chatId: data.id,
          config: { diagnostic: data.diagnostic }
        })
      }
    }
  },
  computed: {
    chats() {
      return this.$store.state.chatState.chatsInfo?.chats ?? null
    },
    hiddenChats() {
      if (this.chats) {
        return this.chats.filter(c => c.hidden)
      }

      return []
    }
  },
  mounted() {
    this.$baseTemplate.saveButton.hide();
  },
  destroyed() {
    this.$baseTemplate.saveButton.show();
  },
};
</script>
